import { FC } from 'react'

import { IIcon } from '@/shared/types/icons.interface'

const Logo: FC<IIcon> = ({ className, width = 160, height = 32 }) => {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox='0 0 160 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect width='32' height='32' rx='16' fill='#DD1405' />
			<path
				d='M6.4 25.0323V12.2581C6.4 9.3363 8.76856 6.96774 11.6903 6.96774L16 13.9613L20.3097 6.96774C23.2314 6.96774 25.6 9.3363 25.6 12.2581V25.0323C22.8493 25.0323 20.6194 22.8023 20.6194 20.0516V14.6581L16 21.729H15.8968L11.3032 14.7097V20.129C11.3032 22.837 9.10798 25.0323 6.4 25.0323Z'
				fill='#FAFAFA'
			/>
			<path
				d='M149.034 21.7692V10.2307H154.49C156.171 10.2307 157.424 10.6428 158.248 11.467C158.93 12.1483 159.27 13.0604 159.27 14.2032C159.27 15.8955 158.479 17.0769 156.897 17.7472L159.649 21.7692C157.343 21.7692 155.19 20.6152 153.912 18.6948L153.633 18.2747H152.232V18.5714C152.232 20.3375 150.8 21.7692 149.034 21.7692ZM152.232 15.7692H154.342C154.88 15.7692 155.298 15.6483 155.595 15.4065C155.902 15.1538 156.056 14.8076 156.056 14.3681C156.056 13.9175 155.902 13.5769 155.595 13.3461C155.298 13.1153 154.875 12.9999 154.325 12.9999H152.232V15.7692Z'
				fill='var(--third-color)'
			/>
			<path
				d='M141.04 21.7692C139.383 21.7692 138.04 20.426 138.04 18.7692V13.2307C138.04 11.5739 139.383 10.2307 141.04 10.2307H144.6C146.102 10.2307 147.32 11.4484 147.32 12.9505H141.205V14.6977H145.482C146.178 14.6977 146.743 15.2623 146.743 15.9587C146.743 16.6552 146.178 17.2197 145.482 17.2197H141.205V19.0494H147.402C147.402 20.5515 146.185 21.7692 144.683 21.7692H141.04Z'
				fill='var(--third-color)'
			/>
			<path
				d='M127.882 21.7692V10.2307C129.649 10.2307 131.08 11.6624 131.08 13.4285V18.967H136.668C136.668 20.5146 135.414 21.7692 133.866 21.7692H127.882Z'
				fill='var(--third-color)'
			/>
			<path
				d='M125.77 21.7692C123.995 21.7692 122.556 20.3301 122.556 18.5549V10.2307C124.331 10.2307 125.77 11.6698 125.77 13.445V21.7692Z'
				fill='var(--third-color)'
			/>
			<path
				d='M108.464 21.7693L112.776 11.568C113.139 10.7076 113.983 10.1484 114.917 10.1484C115.851 10.1484 116.695 10.7076 117.058 11.568L121.37 21.7693H120.167C118.821 21.7693 117.609 20.9549 117.101 19.7088H112.65C112.153 20.9533 110.947 21.7693 109.607 21.7693H108.464ZM113.59 17.2198H116.178L114.892 13.9396L113.59 17.2198Z'
				fill='var(--third-color)'
			/>
			<path
				d='M95.0764 21.7692V13.6098C95.0764 11.7436 96.5892 10.2307 98.4555 10.2307L101.208 14.6977L103.961 10.2307C105.827 10.2307 107.34 11.7436 107.34 13.6098V21.7692C105.583 21.7692 104.159 20.3448 104.159 18.5879V15.1428L101.208 19.6593H101.142L98.2082 15.1758V18.6373C98.2082 20.367 96.806 21.7692 95.0764 21.7692Z'
				fill='var(--third-color)'
			/>
			<path
				d='M81.0502 21.7693L85.3622 11.568C85.7259 10.7076 86.5693 10.1484 87.5035 10.1484C88.4376 10.1484 89.281 10.7076 89.6447 11.568L93.9568 21.7693H92.7535C91.4078 21.7693 90.1959 20.9549 89.6875 19.7088H85.237C84.7392 20.9533 83.534 21.7693 82.1937 21.7693H81.0502ZM86.1766 17.2198H88.7645L87.4787 13.9396L86.1766 17.2198Z'
				fill='var(--third-color)'
			/>
			<path
				d='M75.1894 22C73.3762 22 71.8762 21.4396 70.6894 20.3187C69.5026 19.1868 68.9091 17.7473 68.9091 16C68.9091 14.3077 69.4971 12.8846 70.6729 11.7308C71.8597 10.5769 73.3432 10 75.1234 10C75.8758 10 76.5833 10.0953 77.2459 10.286C78.8137 10.7371 78.9691 12.6856 77.9256 13.9396C77.0575 13.2143 76.1289 12.8516 75.1399 12.8516C74.3048 12.8516 73.6015 13.1593 73.03 13.7747C72.4696 14.3901 72.1894 15.1484 72.1894 16.0495C72.1894 16.9835 72.4806 17.7527 73.063 18.3571C73.6564 18.9505 74.4092 19.2473 75.3212 19.2473C76.0685 19.2473 76.6839 19.0879 77.1674 18.7692V17.3681H74.8927C74.8927 16.0754 75.9406 15.0275 77.2333 15.0275H80.2004V17.8618C80.2004 19.3549 79.5709 20.868 78.1886 21.4326C77.2624 21.8109 76.2627 22 75.1894 22Z'
				fill='var(--third-color)'
			/>
			<path
				d='M61.3104 21.7692C59.6535 21.7692 58.3104 20.426 58.3104 18.7692V13.2307C58.3104 11.5739 59.6535 10.2307 61.3104 10.2307H64.8708C66.3729 10.2307 67.5906 11.4484 67.5906 12.9505H61.4752V14.6977H65.7527C66.4491 14.6977 67.0137 15.2623 67.0137 15.9587C67.0137 16.6552 66.4491 17.2197 65.7527 17.2197H61.4752V19.0494H64.9533C66.4553 19.0494 67.673 20.2671 67.673 21.7692H61.3104Z'
				fill='var(--third-color)'
			/>
			<path
				d='M44 21.7692V13.6098C44 11.7436 45.5129 10.2307 47.3791 10.2307L50.1319 14.6977L52.8846 10.2307C54.7508 10.2307 56.2637 11.7436 56.2637 13.6098V21.7692C54.5067 21.7692 53.0824 20.3448 53.0824 18.5879V15.1428L50.1319 19.6593H50.0659L47.1319 15.1758V18.6373C47.1319 20.367 45.7297 21.7692 44 21.7692Z'
				fill='var(--third-color)'
			/>
		</svg>
	)
}

export default Logo
