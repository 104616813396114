import { FC } from 'react'

import { IIcon } from '@/shared/types/icons.interface'

const Select: FC<IIcon> = ({ className, width = 20, height = 20 }) => {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill='var(--third-color)'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M10.0041 18.1259C6.91413 18.1262 4.06476 16.3556 2.70007 13.5687C1.31913 10.7478 1.73726 7.22968 3.74101 4.81437C5.74257 2.40156 8.99663 1.34874 12.0304 2.13218C12.3644 2.21843 12.5654 2.55937 12.4794 2.89374C12.3932 3.22812 12.0519 3.42874 11.7179 3.34281C9.15163 2.67999 6.39726 3.57062 4.70319 5.61281C3.00819 7.65624 2.65444 10.6328 3.82288 13.0197C4.98694 15.3972 7.55476 16.9459 10.1904 16.8741C12.8257 16.8022 15.2173 15.1994 16.2832 12.7909C16.9091 11.3766 17.0416 9.77499 16.6557 8.28093C16.5694 7.94687 16.7704 7.60593 17.1048 7.51937C17.4382 7.43281 17.7798 7.63406 17.8663 7.96843C18.3219 9.73374 18.166 11.6262 17.4266 13.2969C16.1666 16.1437 13.3394 18.0384 10.2248 18.1234C10.151 18.125 10.0773 18.1259 10.0041 18.1259Z' />
			<path d='M9.99992 11.95C9.83992 11.95 9.67992 11.8891 9.55805 11.7669C9.31398 11.5228 9.31398 11.1272 9.55805 10.8831L17.058 3.38312C17.3018 3.13906 17.698 3.13906 17.9418 3.38312C18.1859 3.62718 18.1859 4.02281 17.9418 4.26687L10.4418 11.7669C10.3199 11.8887 10.1599 11.95 9.99992 11.95Z' />
			<path d='M9.99914 11.95C9.83914 11.95 9.67914 11.8891 9.55727 11.7669L6.9057 9.11532C6.66164 8.87126 6.66164 8.47563 6.9057 8.23157C7.14945 7.98751 7.5457 7.98751 7.78945 8.23157L10.441 10.8831C10.6851 11.1272 10.6851 11.5228 10.441 11.7669C10.3191 11.8888 10.1591 11.95 9.99914 11.95Z' />
		</svg>
	)
}

export default Select
