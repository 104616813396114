import { FC } from 'react'

import { IIcon } from '@/shared/types/icons.interface'

const Save: FC<IIcon> = ({ className, width = 21, height = 20 }) => {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox='0 0 21 20'
			fill='var(--third-color)'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_104_3655)'>
				<path d='M19.557 4.55625L15.807 0.806245C15.7486 0.748319 15.6794 0.702491 15.6032 0.671388C15.5271 0.640285 15.4455 0.62452 15.3633 0.624995H2.86328C2.366 0.624995 1.88909 0.822539 1.53746 1.17417C1.18583 1.5258 0.988281 2.00271 0.988281 2.5V17.5C0.988281 17.9973 1.18583 18.4742 1.53746 18.8258C1.88909 19.1775 2.366 19.375 2.86328 19.375H17.8633C18.3606 19.375 18.8375 19.1775 19.1891 18.8258C19.5407 18.4742 19.7383 17.9973 19.7383 17.5V5C19.7388 4.91774 19.723 4.8362 19.6919 4.76005C19.6608 4.68391 19.615 4.61465 19.557 4.55625ZM12.8633 1.875V5.625H7.86328V1.875H12.8633ZM5.36328 18.125V13.75C5.36328 13.5842 5.42913 13.4253 5.54634 13.3081C5.66355 13.1908 5.82252 13.125 5.98828 13.125H14.7383C14.904 13.125 15.063 13.1908 15.1802 13.3081C15.2974 13.4253 15.3633 13.5842 15.3633 13.75V18.125H5.36328ZM18.4883 17.5C18.4883 17.6658 18.4224 17.8247 18.3052 17.9419C18.188 18.0591 18.029 18.125 17.8633 18.125H16.6133V13.75C16.6133 13.2527 16.4157 12.7758 16.0641 12.4242C15.7125 12.0725 15.2356 11.875 14.7383 11.875H5.98828C5.491 11.875 5.01409 12.0725 4.66246 12.4242C4.31083 12.7758 4.11328 13.2527 4.11328 13.75V18.125H2.86328C2.69752 18.125 2.53855 18.0591 2.42134 17.9419C2.30413 17.8247 2.23828 17.6658 2.23828 17.5V2.5C2.23828 2.33423 2.30413 2.17526 2.42134 2.05805C2.53855 1.94084 2.69752 1.875 2.86328 1.875H6.61328V5.625C6.61328 5.95652 6.74498 6.27446 6.9794 6.50888C7.21382 6.7433 7.53176 6.875 7.86328 6.875H12.8633C13.1948 6.875 13.5127 6.7433 13.7472 6.50888C13.9816 6.27446 14.1133 5.95652 14.1133 5.625V1.875H15.107L18.4883 5.25625V17.5Z' />
			</g>
			<defs>
				<clipPath id='clip0_104_3655'>
					<rect
						width='20'
						height='20'
						fill='white'
						transform='translate(0.363281)'
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default Save
