import { FC } from 'react'

import { IIcon } from '@/shared/types/icons.interface'

const Theme: FC<IIcon> = ({ className, width = 21, height = 20 }) => {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox='0 0 21 20'
			fill='none'
			stroke='var(--third-color)'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M18.4806 9.99995C18.4806 10.4008 18.1556 10.7258 17.7548 10.7258C17.3539 10.7258 17.029 10.4008 17.029 9.99995C17.029 9.5991 17.3539 9.27415 17.7548 9.27415C18.1556 9.27415 18.4806 9.5991 18.4806 9.99995Z'
				strokeWidth='1.5'
			/>
			<path
				d='M4.93208 9.99995C4.93208 10.4008 4.60713 10.7258 4.20628 10.7258C3.80542 10.7258 3.48047 10.4008 3.48047 9.99995C3.48047 9.5991 3.80542 9.27415 4.20628 9.27415C4.60713 9.27415 4.93208 9.5991 4.93208 9.99995Z'
				strokeWidth='1.5'
			/>
			<path
				d='M6.8674 14.8387C6.8674 15.2395 6.54244 15.5645 6.14159 15.5645C5.74074 15.5645 5.41579 15.2395 5.41579 14.8387C5.41579 14.4378 5.74074 14.1129 6.14159 14.1129C6.54244 14.1129 6.8674 14.4378 6.8674 14.8387Z'
				strokeWidth='1.5'
			/>
			<path
				d='M11.7061 16.7742C11.7061 17.1751 11.3812 17.5 10.9803 17.5C10.5795 17.5 10.2545 17.1751 10.2545 16.7742C10.2545 16.3734 10.5795 16.0484 10.9803 16.0484C11.3812 16.0484 11.7061 16.3734 11.7061 16.7742Z'
				strokeWidth='1.5'
			/>
			<path
				d='M11.7061 3.22581C11.7061 3.62666 11.3812 3.95161 10.9803 3.95161C10.5795 3.95161 10.2545 3.62666 10.2545 3.22581C10.2545 2.82495 10.5795 2.5 10.9803 2.5C11.3812 2.5 11.7061 2.82495 11.7061 3.22581Z'
				strokeWidth='1.5'
			/>
			<path
				d='M6.8674 5.16134C6.8674 5.56219 6.54244 5.88715 6.14159 5.88715C5.74074 5.88715 5.41579 5.56219 5.41579 5.16134C5.41579 4.76049 5.74074 4.43553 6.14159 4.43553C6.54244 4.43553 6.8674 4.76049 6.8674 5.16134Z'
				strokeWidth='1.5'
			/>
			<path
				d='M16.5448 14.8387C16.5448 15.2395 16.2199 15.5645 15.819 15.5645C15.4182 15.5645 15.0932 15.2395 15.0932 14.8387C15.0932 14.4378 15.4182 14.1129 15.819 14.1129C16.2199 14.1129 16.5448 14.4378 16.5448 14.8387Z'
				strokeWidth='1.5'
			/>
			<path
				d='M16.5448 5.16134C16.5448 5.56219 16.2199 5.88715 15.819 5.88715C15.4182 5.88715 15.0932 5.56219 15.0932 5.16134C15.0932 4.76049 15.4182 4.43553 15.819 4.43553C16.2199 4.43553 16.5448 4.76049 16.5448 5.16134Z'
				strokeWidth='1.5'
			/>
			<path
				d='M14.889 10.0376C14.889 12.1755 13.1559 13.9086 11.018 13.9086C8.88015 13.9086 7.14705 12.1755 7.14705 10.0376C7.14705 7.89976 8.88015 6.16667 11.018 6.16667C13.1559 6.16667 14.889 7.89976 14.889 10.0376Z'
				strokeWidth='1.5'
			/>
		</svg>
	)
}

export default Theme
