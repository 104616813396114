import { FC } from 'react'

import { IIcon } from '@/shared/types/icons.interface'

const Database: FC<IIcon> = ({ className, width = 18, height = 20 }) => {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox='0 0 18 20'
			fill='var(--third-color)'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_104_3982)'>
				<path d='M9.18672 7.05664C6.88867 7.05664 4.72266 6.72148 3.0875 6.11328C1.33086 5.45937 0.363281 4.54141 0.363281 3.52852C0.363281 2.51562 1.33086 1.59727 3.0875 0.943359C4.72266 0.335156 6.88867 0 9.18672 0C11.4848 0 13.6504 0.335156 15.2852 0.943359C17.043 1.59727 18.0094 2.51523 18.0094 3.52852C18.0094 4.5418 17.043 5.45937 15.2852 6.11328C13.6504 6.72148 11.4844 7.05664 9.18672 7.05664ZM9.18672 0.78125C4.44727 0.78125 1.14453 2.22891 1.14453 3.52852C1.14453 4.82812 4.44727 6.27539 9.18672 6.27539C13.9262 6.27539 17.2285 4.82773 17.2285 3.52852C17.2285 2.2293 13.9258 0.78125 9.18672 0.78125Z' />
				<path d='M9.18672 11.3711C6.88867 11.3711 4.72266 11.0359 3.0875 10.4277C1.33086 9.77384 0.363281 8.85587 0.363281 7.84259C0.363281 7.73899 0.404436 7.63963 0.477693 7.56638C0.550949 7.49312 0.650306 7.45197 0.753906 7.45197C0.857506 7.45197 0.956863 7.49312 1.03012 7.56638C1.10338 7.63963 1.14453 7.73899 1.14453 7.84259C1.14453 9.14064 4.44727 10.5899 9.18672 10.5899C13.9262 10.5899 17.2285 9.14064 17.2285 7.84259C17.2285 7.73899 17.2697 7.63963 17.3429 7.56638C17.4162 7.49312 17.5155 7.45197 17.6191 7.45197C17.7227 7.45197 17.8221 7.49312 17.8954 7.56638C17.9686 7.63963 18.0098 7.73899 18.0098 7.84259C18.0098 8.85587 17.0426 9.77384 15.2855 10.4277C13.6504 11.0359 11.4844 11.3711 9.18672 11.3711Z' />
				<path d='M9.18672 15.6856C6.88867 15.6856 4.72266 15.3504 3.0875 14.7422C1.33086 14.0883 0.363281 13.1703 0.363281 12.157C0.363281 12.0534 0.404436 11.9541 0.477693 11.8808C0.550949 11.8076 0.650306 11.7664 0.753906 11.7664C0.857506 11.7664 0.956863 11.8076 1.03012 11.8808C1.10338 11.9541 1.14453 12.0534 1.14453 12.157C1.14453 13.4567 4.44727 14.9043 9.18672 14.9043C13.9262 14.9043 17.2285 13.4567 17.2285 12.157C17.2285 12.0534 17.2697 11.9541 17.3429 11.8808C17.4162 11.8076 17.5155 11.7664 17.6191 11.7664C17.7227 11.7664 17.8221 11.8076 17.8954 11.8808C17.9686 11.9541 18.0098 12.0534 18.0098 12.157C18.0098 13.1703 17.0426 14.0883 15.2855 14.7422C13.6504 15.3516 11.4844 15.6856 9.18672 15.6856Z' />
				<path d='M9.18672 20C6.88867 20 4.72266 19.6648 3.0875 19.0562C1.33086 18.4027 0.363281 17.4848 0.363281 16.4715V3.5285C0.363281 3.4249 0.404436 3.32555 0.477693 3.25229C0.550949 3.17903 0.650306 3.13788 0.753906 3.13788C0.857506 3.13788 0.956863 3.17903 1.03012 3.25229C1.10338 3.32555 1.14453 3.4249 1.14453 3.5285V16.4715C1.14453 17.7711 4.44727 19.2187 9.18672 19.2187C13.9262 19.2187 17.2285 17.7711 17.2285 16.4715V3.5285C17.2285 3.4249 17.2697 3.32555 17.3429 3.25229C17.4162 3.17903 17.5155 3.13788 17.6191 3.13788C17.7227 3.13788 17.8221 3.17903 17.8954 3.25229C17.9686 3.32555 18.0098 3.4249 18.0098 3.5285V16.4715C18.0098 17.4848 17.0426 18.4027 15.2855 19.0562C13.6504 19.6648 11.4844 20 9.18672 20Z' />
				<path d='M15.6582 8.23359C15.6326 8.23353 15.6071 8.23091 15.582 8.22578C15.557 8.22074 15.5326 8.21328 15.509 8.20351C15.4853 8.19381 15.4627 8.18177 15.4414 8.16758C15.3985 8.1393 15.3619 8.10262 15.3336 8.05976C15.3196 8.03839 15.3075 8.01577 15.2977 7.99219C15.2879 7.96861 15.2804 7.94415 15.2754 7.91914C15.265 7.86876 15.265 7.81678 15.2754 7.7664C15.2852 7.71605 15.3049 7.66816 15.3334 7.6255C15.3619 7.58285 15.3986 7.5463 15.4414 7.51797C15.4627 7.50378 15.4853 7.49174 15.509 7.48203C15.5326 7.47226 15.557 7.46481 15.582 7.45976C15.6324 7.44961 15.6844 7.44961 15.7348 7.45976C15.8103 7.47506 15.8797 7.51213 15.9344 7.5664C15.9524 7.5848 15.9688 7.6048 15.9832 7.62617C16.0116 7.66866 16.0314 7.7163 16.0414 7.7664C16.0513 7.81683 16.0513 7.86871 16.0414 7.91914C16.0364 7.94415 16.0289 7.96861 16.0191 7.99219C16.0093 8.01577 15.9972 8.03839 15.9832 8.05976C15.9689 8.08108 15.9525 8.10097 15.9344 8.11914C15.9163 8.13728 15.8964 8.15351 15.875 8.16758C15.8538 8.18168 15.8313 8.19372 15.8078 8.20351C15.7842 8.21323 15.7598 8.22068 15.7348 8.22578C15.7096 8.2309 15.6839 8.23351 15.6582 8.23359Z' />
				<path d='M14.0899 8.62578C14.0643 8.62571 14.0388 8.6231 14.0137 8.61797C13.9887 8.61292 13.9642 8.60547 13.9406 8.5957C13.917 8.58599 13.8943 8.57395 13.8731 8.55976C13.8517 8.54569 13.8318 8.52946 13.8137 8.51133C13.7772 8.47509 13.7484 8.43192 13.7289 8.38437C13.7191 8.3608 13.7117 8.33634 13.7067 8.31133C13.7015 8.28561 13.699 8.25943 13.6992 8.2332C13.6991 8.20698 13.7016 8.1808 13.7067 8.15508C13.7118 8.13009 13.7193 8.10564 13.7289 8.08203C13.7486 8.03469 13.7774 7.99168 13.8137 7.95547C13.8318 7.93721 13.8516 7.92084 13.8731 7.90664C13.8943 7.89245 13.917 7.88041 13.9406 7.8707C13.9642 7.86094 13.9887 7.85348 14.0137 7.84844C14.064 7.83828 14.1158 7.83828 14.166 7.84844C14.191 7.85348 14.2155 7.86094 14.2391 7.8707C14.2627 7.88041 14.2854 7.89245 14.3067 7.90664C14.3494 7.9352 14.3861 7.97198 14.4145 8.01484C14.4286 8.03603 14.4406 8.05853 14.4504 8.08203C14.4602 8.10558 14.4677 8.13005 14.4727 8.15508C14.4779 8.18079 14.4805 8.20696 14.4805 8.2332C14.4805 8.25944 14.4779 8.28562 14.4727 8.31133C14.4678 8.33638 14.4603 8.36087 14.4504 8.38437C14.4406 8.408 14.4286 8.43063 14.4145 8.45195C14.3862 8.49481 14.3495 8.53149 14.3067 8.55976C14.2854 8.57395 14.2627 8.58599 14.2391 8.5957C14.2155 8.60547 14.191 8.61292 14.166 8.61797C14.141 8.6231 14.1154 8.62571 14.0899 8.62578Z' />
				<path d='M12.5211 9.01797C12.4949 9.01799 12.4687 9.01538 12.443 9.01015C12.418 9.00506 12.3935 8.9976 12.3699 8.98789C12.2983 8.95874 12.2372 8.90864 12.1945 8.84414C12.1803 8.82286 12.1683 8.80022 12.1586 8.77656C12.1488 8.75299 12.1414 8.72853 12.1363 8.70351C12.1313 8.67843 12.1288 8.65292 12.1289 8.62734C12.1288 8.60112 12.1312 8.57494 12.1363 8.54922C12.1421 8.5243 12.1502 8.49998 12.1606 8.47656C12.1802 8.42932 12.2089 8.38634 12.2449 8.35C12.2633 8.33194 12.2833 8.31559 12.3047 8.30117C12.3259 8.28706 12.3484 8.27503 12.3719 8.26523C12.3955 8.25552 12.4199 8.24807 12.4449 8.24297C12.4953 8.23281 12.5473 8.23281 12.5977 8.24297C12.6227 8.24801 12.6471 8.25547 12.6707 8.26523C12.6943 8.27511 12.7169 8.28714 12.7383 8.30117C12.7595 8.3156 12.7794 8.33194 12.7977 8.35C12.8158 8.36808 12.832 8.38797 12.8461 8.40937C12.8602 8.43056 12.8722 8.45306 12.882 8.47656C12.8917 8.50017 12.8992 8.52462 12.9043 8.54961C12.9095 8.57532 12.9121 8.60149 12.9121 8.62773C12.9121 8.65332 12.9094 8.67884 12.9043 8.7039C12.8993 8.72892 12.8918 8.75338 12.882 8.77695C12.8723 8.80061 12.8603 8.82325 12.8461 8.84453C12.832 8.86593 12.8158 8.88582 12.7977 8.9039C12.7795 8.92196 12.7596 8.93818 12.7383 8.95234C12.7169 8.96638 12.6943 8.97841 12.6707 8.98828C12.6471 8.99805 12.6227 9.0055 12.5977 9.01054C12.5725 9.01555 12.5468 9.01804 12.5211 9.01797Z' />
				<path d='M15.6582 12.5477C15.6326 12.5477 15.6071 12.5452 15.582 12.5402C15.557 12.5352 15.5326 12.5277 15.509 12.518C15.4853 12.5083 15.4627 12.4962 15.4414 12.482C15.42 12.4678 15.4001 12.4515 15.382 12.4332C15.309 12.3598 15.2679 12.2606 15.2676 12.157C15.2675 12.1308 15.2702 12.1046 15.2754 12.0789C15.2804 12.0539 15.2879 12.0294 15.2977 12.0059C15.3075 11.9823 15.3196 11.9597 15.3336 11.9383C15.3478 11.9169 15.364 11.8971 15.382 11.8789C15.4001 11.8608 15.42 11.8445 15.4414 11.8305C15.4627 11.8163 15.4853 11.8042 15.509 11.7945C15.5326 11.7848 15.557 11.7773 15.582 11.7723C15.6324 11.7621 15.6844 11.7621 15.7348 11.7723C15.7849 11.7823 15.8325 11.8021 15.875 11.8305C15.8964 11.8445 15.9163 11.8608 15.9344 11.8789C15.9524 11.8972 15.9688 11.917 15.9832 11.9383C15.9972 11.9597 16.0093 11.9823 16.0191 12.0059C16.0289 12.0294 16.0364 12.0539 16.0414 12.0789C16.0465 12.1046 16.049 12.1308 16.0488 12.157C16.0486 12.2606 16.0074 12.3598 15.9344 12.4332C15.9163 12.4515 15.8964 12.4678 15.875 12.482C15.8325 12.5104 15.7849 12.5302 15.7348 12.5402C15.7096 12.5452 15.6839 12.5477 15.6582 12.5477Z' />
				<path d='M14.0898 12.9398C14.0643 12.9399 14.0388 12.9374 14.0137 12.9324C13.9887 12.9274 13.9642 12.9199 13.9406 12.9101C13.917 12.9004 13.8943 12.8884 13.873 12.8742C13.8517 12.8601 13.8318 12.8439 13.8137 12.8258C13.7956 12.8075 13.7793 12.7876 13.7648 12.7664C13.7508 12.745 13.7388 12.7224 13.7289 12.6988C13.7191 12.6752 13.7117 12.6508 13.7066 12.6258C13.6967 12.5753 13.6967 12.5235 13.7066 12.473C13.7117 12.448 13.7191 12.4236 13.7289 12.4C13.7388 12.3764 13.7508 12.3538 13.7648 12.3324C13.7793 12.3112 13.7956 12.2913 13.8137 12.273C13.8318 12.2549 13.8517 12.2387 13.873 12.2246C13.8943 12.2104 13.917 12.1984 13.9406 12.1887C13.9642 12.1789 13.9887 12.1714 14.0137 12.1664C14.0639 12.1562 14.1157 12.1562 14.166 12.1664C14.191 12.1714 14.2155 12.1789 14.2391 12.1887C14.2627 12.1984 14.2854 12.2104 14.3066 12.2246C14.328 12.2387 14.3479 12.2549 14.366 12.273C14.3841 12.2912 14.4003 12.3111 14.4145 12.3324C14.4285 12.3538 14.4405 12.3764 14.4504 12.4C14.4603 12.4235 14.4678 12.448 14.4727 12.473C14.4831 12.5234 14.4831 12.5754 14.4727 12.6258C14.4678 12.6508 14.4603 12.6753 14.4504 12.6988C14.4405 12.7224 14.4285 12.745 14.4145 12.7664C14.4003 12.7877 14.3841 12.8076 14.366 12.8258C14.3479 12.8439 14.328 12.8601 14.3066 12.8742C14.2854 12.8884 14.2627 12.9004 14.2391 12.9101C14.2155 12.9199 14.191 12.9274 14.166 12.9324C14.1409 12.9374 14.1154 12.9399 14.0898 12.9398Z' />
				<path d='M12.5211 13.332C12.4949 13.3322 12.4687 13.3297 12.443 13.3246C12.418 13.3195 12.3935 13.3121 12.3699 13.3024C12.3226 13.2825 12.2795 13.2539 12.243 13.218C12.2068 13.1816 12.1781 13.1385 12.1586 13.091C12.1488 13.0675 12.1414 13.043 12.1363 13.018C12.1313 12.9929 12.1289 12.9674 12.1289 12.9418C12.1291 12.8382 12.1701 12.7389 12.243 12.6652C12.2614 12.6473 12.2814 12.6311 12.3027 12.6168C12.3239 12.6027 12.3464 12.5907 12.3699 12.5809C12.3935 12.5712 12.418 12.5637 12.443 12.5586C12.5188 12.5432 12.5974 12.5508 12.6689 12.5804C12.7404 12.61 12.8014 12.6602 12.8441 12.7246C12.8582 12.746 12.8702 12.7686 12.8801 12.7922C12.8898 12.8158 12.8973 12.8402 12.9023 12.8652C12.9128 12.9156 12.9128 12.9676 12.9023 13.018C12.8973 13.043 12.8898 13.0675 12.8801 13.091C12.8702 13.1146 12.8582 13.1372 12.8441 13.1586C12.8159 13.2015 12.7792 13.2381 12.7363 13.2664C12.715 13.2805 12.6923 13.2925 12.6688 13.3024C12.6452 13.3121 12.6207 13.3196 12.5957 13.3246C12.5711 13.3295 12.5461 13.332 12.5211 13.332Z' />
				<path d='M15.6582 16.8621C15.6326 16.8622 15.6071 16.8597 15.582 16.8547C15.557 16.8497 15.5326 16.8422 15.509 16.8324C15.4853 16.8228 15.4627 16.8109 15.4414 16.7969C15.4201 16.7826 15.4002 16.7662 15.382 16.7481C15.3639 16.73 15.3477 16.7101 15.3336 16.6887C15.3195 16.6675 15.3075 16.645 15.2977 16.6215C15.2879 16.5979 15.2805 16.5734 15.2754 16.5484C15.2702 16.5227 15.2676 16.4966 15.2676 16.4703C15.2676 16.4447 15.2703 16.4192 15.2754 16.3942C15.2804 16.3691 15.2879 16.3447 15.2977 16.3211C15.3074 16.2974 15.3194 16.2748 15.3336 16.2535C15.3619 16.2107 15.3985 16.174 15.4414 16.1457C15.4627 16.1315 15.4853 16.1195 15.509 16.1098C15.5326 16.1 15.557 16.0926 15.582 16.0875C15.6324 16.0774 15.6844 16.0774 15.7348 16.0875C15.7598 16.0926 15.7842 16.1001 15.8078 16.1098C15.8313 16.1196 15.8538 16.1316 15.875 16.1457C15.8964 16.1598 15.9163 16.176 15.9344 16.1942C15.9525 16.2123 15.9689 16.2322 15.9832 16.2535C15.9974 16.2748 16.0094 16.2974 16.0191 16.3211C16.0289 16.3447 16.0364 16.3691 16.0414 16.3942C16.0464 16.4192 16.0489 16.4447 16.0488 16.4703C16.049 16.4965 16.0465 16.5227 16.0414 16.5484C16.0363 16.5734 16.0289 16.5979 16.0191 16.6215C15.9947 16.6809 15.9559 16.7334 15.9063 16.7743C15.8567 16.8152 15.7978 16.8432 15.7348 16.8559C15.7095 16.8605 15.6839 16.8626 15.6582 16.8621Z' />
				<path d='M14.0898 17.2543C14.0643 17.2544 14.0388 17.2519 14.0137 17.2469C13.9887 17.2418 13.9642 17.2344 13.9406 17.2246C13.917 17.2149 13.8943 17.2029 13.873 17.1887C13.8303 17.16 13.7935 17.1232 13.7648 17.0805C13.751 17.0592 13.7391 17.0367 13.7293 17.0133C13.7194 16.9898 13.7119 16.9653 13.707 16.9402C13.7018 16.9145 13.6992 16.8883 13.6992 16.8621C13.6993 16.8365 13.7019 16.811 13.707 16.7859C13.7119 16.7609 13.7194 16.7364 13.7293 16.7129C13.739 16.6893 13.7509 16.6667 13.7648 16.6453C13.7792 16.624 13.7955 16.6041 13.8137 16.5859C13.8318 16.5678 13.8516 16.5516 13.873 16.5375C13.8943 16.5233 13.917 16.5113 13.9406 16.5016C13.9642 16.4918 13.9887 16.4843 14.0137 16.4793C14.0639 16.4691 14.1157 16.4691 14.166 16.4793C14.191 16.4843 14.2155 16.4918 14.2391 16.5016C14.2627 16.5113 14.2854 16.5233 14.3066 16.5375C14.328 16.5516 14.3479 16.5678 14.366 16.5859C14.3841 16.6041 14.4003 16.624 14.4145 16.6453C14.4286 16.6666 14.4407 16.6892 14.4504 16.7129C14.4603 16.7364 14.4678 16.7609 14.4727 16.7859C14.4778 16.811 14.4804 16.8365 14.4805 16.8621C14.4805 16.8883 14.4779 16.9145 14.4727 16.9402C14.4678 16.9653 14.4603 16.9898 14.4504 17.0133C14.4406 17.0368 14.4286 17.0593 14.4145 17.0805C14.4004 17.1019 14.3842 17.1218 14.366 17.1398C14.3478 17.158 14.328 17.1743 14.3066 17.1887C14.2854 17.2029 14.2627 17.2149 14.2391 17.2246C14.2155 17.2344 14.191 17.2418 14.166 17.2469C14.1409 17.2519 14.1154 17.2544 14.0898 17.2543Z' />
				<path d='M12.5211 17.6469C12.4949 17.6469 12.4687 17.6443 12.443 17.639C12.418 17.6339 12.3935 17.6265 12.3699 17.6168C12.2986 17.5872 12.2376 17.5371 12.1947 17.4729C12.1518 17.4086 12.1289 17.3331 12.1289 17.2558C12.1289 17.2303 12.1313 17.2048 12.1363 17.1797C12.1414 17.1547 12.1488 17.1302 12.1586 17.1066C12.1781 17.0592 12.2068 17.016 12.243 16.9797C12.2795 16.9437 12.3226 16.9151 12.3699 16.8953C12.3935 16.8856 12.418 16.8781 12.443 16.873C12.4934 16.8629 12.5453 16.8629 12.5957 16.873C12.6207 16.8781 12.6452 16.8855 12.6688 16.8953C12.6923 16.9052 12.715 16.9172 12.7363 16.9312C12.7792 16.9595 12.8159 16.9962 12.8441 17.039C12.8582 17.0604 12.8702 17.083 12.8801 17.1066C12.8898 17.1302 12.8973 17.1547 12.9023 17.1797C12.9075 17.2047 12.9101 17.2303 12.9102 17.2558C12.9102 17.3201 12.8943 17.3834 12.864 17.4401C12.8337 17.4968 12.7898 17.5452 12.7363 17.5808C12.715 17.5949 12.6923 17.6069 12.6688 17.6168C12.6452 17.6265 12.6207 17.634 12.5957 17.639C12.5711 17.6441 12.5462 17.6467 12.5211 17.6469Z' />
			</g>
			<defs>
				<clipPath id='clip0_104_3982'>
					<rect
						width='17.6465'
						height='20'
						fill='white'
						transform='translate(0.363281)'
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default Database
