import { FC } from 'react'

import { IIcon } from '@/shared/types/icons.interface'

const Remove: FC<IIcon> = ({ className, width = 21, height = 20 }) => {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox='0 0 21 20'
			fill='var(--third-color)'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M2.23828 4.375H3.48828V17.175C3.48993 17.758 3.72224 18.3166 4.13447 18.7288C4.54669 19.141 5.10531 19.3734 5.68828 19.375H15.0633C15.6419 19.3668 16.1941 19.1316 16.601 18.7201C17.0078 18.3086 17.2367 17.7537 17.2383 17.175V4.375H18.4883C18.654 4.375 18.813 4.30915 18.9302 4.19194C19.0474 4.07473 19.1133 3.91576 19.1133 3.75C19.1133 3.58424 19.0474 3.42527 18.9302 3.30806C18.813 3.19085 18.654 3.125 18.4883 3.125H2.23828C2.07252 3.125 1.91355 3.19085 1.79634 3.30806C1.67913 3.42527 1.61328 3.58424 1.61328 3.75C1.61328 3.91576 1.67913 4.07473 1.79634 4.19194C1.91355 4.30915 2.07252 4.375 2.23828 4.375ZM15.9883 4.375V17.175C15.9883 17.427 15.8882 17.6686 15.71 17.8468C15.5319 18.0249 15.2902 18.125 15.0383 18.125H5.66328C5.41568 18.1185 5.18042 18.0155 5.00763 17.8381C4.83485 17.6606 4.7382 17.4227 4.73828 17.175V4.375H15.9883Z' />
			<path d='M7.86328 1.875H12.8633C13.029 1.875 13.188 1.80915 13.3052 1.69194C13.4224 1.57473 13.4883 1.41576 13.4883 1.25C13.4883 1.08424 13.4224 0.925268 13.3052 0.808058C13.188 0.690848 13.029 0.625 12.8633 0.625H7.86328C7.69752 0.625 7.53855 0.690848 7.42134 0.808058C7.30413 0.925268 7.23828 1.08424 7.23828 1.25C7.23828 1.41576 7.30413 1.57473 7.42134 1.69194C7.53855 1.80915 7.69752 1.875 7.86328 1.875Z' />
			<path d='M8.28906 15.625C8.45482 15.625 8.61379 15.5592 8.731 15.4419C8.84821 15.3247 8.91406 15.1658 8.91406 15V7.5C8.91406 7.33424 8.84821 7.17527 8.731 7.05806C8.61379 6.94085 8.45482 6.875 8.28906 6.875C8.1233 6.875 7.96433 6.94085 7.84712 7.05806C7.72991 7.17527 7.66406 7.33424 7.66406 7.5V15C7.66406 15.1658 7.72991 15.3247 7.84712 15.4419C7.96433 15.5592 8.1233 15.625 8.28906 15.625Z' />
			<path d='M12.4375 15.625C12.6033 15.625 12.7622 15.5592 12.8794 15.4419C12.9967 15.3247 13.0625 15.1658 13.0625 15V7.5C13.0625 7.33424 12.9967 7.17527 12.8794 7.05806C12.7622 6.94085 12.6033 6.875 12.4375 6.875C12.2717 6.875 12.1128 6.94085 11.9956 7.05806C11.8783 7.17527 11.8125 7.33424 11.8125 7.5V15C11.8125 15.1658 11.8783 15.3247 11.9956 15.4419C12.1128 15.5592 12.2717 15.625 12.4375 15.625Z' />
		</svg>
	)
}

export default Remove
