import { FC } from 'react'

import { IIcon } from '@/shared/types/icons.interface'

const Start: FC<IIcon> = ({ className, width = 20, height = 20 }) => {
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill='var(--third-color)'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.0007 1.66667C10.4609 1.66667 10.834 2.03977 10.834 2.50001V10C10.834 10.4603 10.4609 10.8333 10.0007 10.8333C9.5404 10.8333 9.16732 10.4603 9.16732 10V2.50001C9.16732 2.03977 9.5404 1.66667 10.0007 1.66667ZM5.28239 4.11066C5.60788 4.43605 5.60797 4.96369 5.28258 5.28918C4.35045 6.22161 3.71573 7.4095 3.45868 8.70267C3.20162 9.99584 3.33376 11.3362 3.83841 12.5542C4.34306 13.7723 5.19754 14.8133 6.29383 15.5458C7.3901 16.2782 8.67898 16.6691 9.9974 16.6691C11.3158 16.6691 12.6047 16.2782 13.701 15.5458C14.7972 14.8133 15.6517 13.7723 16.1564 12.5542C16.6611 11.3362 16.7932 9.99584 16.5362 8.70267C16.2791 7.4095 15.6443 6.22161 14.7122 5.28918C14.3868 4.96369 14.3869 4.43605 14.7124 4.11066C15.0379 3.78528 15.5656 3.78537 15.8909 4.11086C17.0561 5.27641 17.8495 6.76126 18.1708 8.37767C18.4922 9.99417 18.327 11.6696 17.6962 13.1921C17.0653 14.7147 15.9972 16.016 14.6269 16.9316C13.2565 17.8471 11.6455 18.3358 9.9974 18.3358C8.34932 18.3358 6.73828 17.8471 5.36793 16.9316C3.99758 16.016 2.92948 14.7147 2.29867 13.1921C1.66785 11.6696 1.50267 9.99417 1.82399 8.37767C2.14531 6.76126 2.93872 5.27641 4.10388 4.11086C4.42927 3.78537 4.9569 3.78528 5.28239 4.11066Z'
			/>
		</svg>
	)
}

export default Start
